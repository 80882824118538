var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "CompanyAffair" },
    [
      _c(
        "a-card",
        {
          staticClass: "affair-cart",
          attrs: {
            hoverable: "",
            "tab-list": _vm.tabListNoTitle,
            "active-tab-key": _vm.noTitleKey,
            size: "small",
          },
          on: {
            tabChange: function (key) {
              return _vm.onTabChange(key, "noTitleKey")
            },
          },
        },
        [
          _vm.noTitleKey === "2"
            ? _c(
                "div",
                {
                  staticClass: "card-icon",
                  attrs: { slot: "tabBarExtraContent", href: "#" },
                  slot: "tabBarExtraContent",
                },
                [
                  _c(
                    "a-popover",
                    {
                      attrs: {
                        title: "处理类型",
                        placement: "bottom",
                        trigger: "hover",
                        "overlay-class-name": "screen-popover",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "screen-content",
                          attrs: { slot: "content" },
                          slot: "content",
                        },
                        _vm._l(_vm.handleList, function (status) {
                          return _c(
                            "span",
                            { key: status.status, staticClass: "flex-center" },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "user-name",
                                  class: status.selected ? "selected" : "",
                                  on: {
                                    click: function ($event) {
                                      return _vm.conditionQuery(
                                        status,
                                        (_vm.type = "recordType")
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(status.label))]
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                      _c("span", { staticClass: "icon-box" }, [
                        _c("svg", { staticClass: "iconpark-icon icon" }, [
                          _c("use", { attrs: { href: "#edit-name" } }),
                        ]),
                      ]),
                    ]
                  ),
                  _c(
                    "a-popover",
                    {
                      attrs: {
                        title: "审批状态",
                        trigger: "hover",
                        placement: "bottom",
                        "overlay-class-name": "screen-popover",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "screen-content",
                          attrs: { slot: "content" },
                          slot: "content",
                        },
                        _vm._l(_vm.statusList, function (status) {
                          return _c(
                            "span",
                            { key: status.status, staticClass: "flex-center" },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "user-name",
                                  class: status.selected ? "selected" : "",
                                  on: {
                                    click: function ($event) {
                                      return _vm.conditionQuery(
                                        status,
                                        (_vm.type = "recordApprovalStatus")
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(status.label))]
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                      _c("span", { staticClass: "icon-box" }, [
                        _c("svg", { staticClass: "iconpark-icon icon" }, [
                          _c("use", { attrs: { href: "#update-rotation" } }),
                        ]),
                      ]),
                    ]
                  ),
                  _c(
                    "a-popover",
                    {
                      attrs: {
                        title: "事项类型",
                        trigger: "hover",
                        placement: "bottom",
                        "overlay-class-name": "screen-popover",
                        "auto-adjust-overflow": false,
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "screen-content",
                          attrs: { slot: "content" },
                          slot: "content",
                        },
                        _vm._l(_vm.flowSimpleList, function (flow) {
                          return _c(
                            "span",
                            { key: flow.flowId, staticClass: "flex-center" },
                            [
                              flow.flowName !== undefined &&
                              flow.flowName.length < 5
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "user-name",
                                      class: flow.selected ? "selected" : "",
                                      on: {
                                        click: function ($event) {
                                          return _vm.conditionQuery(
                                            flow,
                                            (_vm.type = "flowId")
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(flow.flowName))]
                                  )
                                : _c(
                                    "a-tooltip",
                                    [
                                      _c("template", { slot: "title" }, [
                                        _vm._v(
                                          " " + _vm._s(flow.flowName) + " "
                                        ),
                                      ]),
                                      flow.flowName !== undefined
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "user-name",
                                              class: flow.selected
                                                ? "selected"
                                                : "",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.conditionQuery(
                                                    flow,
                                                    (_vm.type = "flowId")
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  flow.flowName &&
                                                    flow.flowName.slice(0, 3)
                                                ) + "..."
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    2
                                  ),
                            ],
                            1
                          )
                        }),
                        0
                      ),
                      _c("span", { staticClass: "icon-box" }, [
                        _c("svg", { staticClass: "iconpark-icon icon" }, [
                          _c("use", { attrs: { href: "#list-top" } }),
                        ]),
                      ]),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "card-content", on: { scroll: _vm.scrolling } },
            [
              _vm.affairList.length
                ? _c("AffairList", {
                    attrs: {
                      "affair-list": _vm.affairList,
                      "status-name-list": _vm.statusNameList,
                    },
                    on: { refreshList: _vm.getDataList },
                  })
                : _vm._e(),
              !_vm.affairList.length && _vm.isRequest
                ? _c("CbNoData")
                : _vm._e(),
              _vm.isInEnd
                ? _c("div", { staticClass: "in-the-end flex-center" }, [
                    _c("span", [_vm._v("我是有底线的～")]),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }